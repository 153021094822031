import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import logo from "./logo.png"; 
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import theme from "./theme";
import "./App.css";

import { ThemeProvider } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import emailjs from "emailjs-com";
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$ "
    // isNumericString
    />
  );
}





const CompoundInterest = () => {
  const [principal, setPrincipal] = useState(0);
  const [years, setYears] = useState(30);
  let [deposit, setDeposit] = useState(0);
  const [interest, setInterest] = useState(4);
  const [Totalloan, setTotalloan] = useState();
  let [Monthlyresult, setMonthlyResult] = useState();
  const [Fortnightlyresult, setFortnightlyresult] = useState();
  const [Weeklyresult, setWeeklyresult] = useState();
  const [Totalcost, setTotalcost] = useState();
  const [TotalInterest, setTotalInterest] = useState();
  const [emailconfirmation, setemailconfirmation]= useState();

  const calculate = () => {
    var InterestRate = interest / 100 / 12;
    var term = years * 12;

    var Totalloanpre = principal - deposit;
    var Totalloan = "$" + numberWithCommas(Totalloanpre);
 

    var Monthlyresultpre =
      ((principal - deposit) * InterestRate) /
      (1 - Math.pow(1 / (1 + InterestRate), term));
    var Monthlyresult = "$" + numberWithCommas(Math.round(Monthlyresultpre));

    var Totalcost =
      "$" + numberWithCommas(Math.round(Monthlyresultpre * term));
    var TotalInterest =
      "$" +
      numberWithCommas(Math.round(Monthlyresultpre * term - Totalloanpre));
    var Fortnightlyresultpre = Math.round(((Monthlyresultpre * 12) / 365) * 14);
    var Fortnightlyresult = "$" + numberWithCommas(Fortnightlyresultpre);

    var Weeklyresultpre = Math.round(((Monthlyresultpre * 12) / 365) * 7);
    var Weeklyresult = "$" + numberWithCommas(Weeklyresultpre);


    var percentage = (Math.round(Monthlyresultpre * term - Totalloanpre)) / (Math.round(Monthlyresultpre * term));
  

var emailconfirmation = "";
    var interestrateIndicator = (1 - percentage);
    if (percentage >= 0) {
     
      document.getElementById("getStarted").style.display = "none";
      document.getElementById("legends").style.display = "flex";
      document.getElementById("housesvg").style.display = "block";

      document.getElementById("stop1").setAttribute("offset", interestrateIndicator);
    }


    setemailconfirmation(emailconfirmation);

    if (deposit > principal) {

      document.getElementById("housesvg").style.display = "none";
      document.getElementById("legends").style.display = "none";
      document.getElementById("Housepriceerror").style.display = "flex";
      Monthlyresult = "$0";
      Fortnightlyresult = 0;
      Weeklyresult = 0;
      Totalloan = 0;
      TotalInterest = 0;
      Totalcost = 0;

      setMonthlyResult(Monthlyresult);
      setFortnightlyresult(Fortnightlyresult);
      setWeeklyresult(Weeklyresult);
      setTotalloan(Totalloan);
      setTotalInterest(TotalInterest);
      setTotalcost(Totalcost);


    }


    else {

      document.getElementById("Housepriceerror").style.display = "none";
    }
    // alert(percentage);


    setMonthlyResult(Monthlyresult);
    setFortnightlyresult(Fortnightlyresult);
    setWeeklyresult(Weeklyresult);
    setTotalloan(Totalloan);
    setTotalInterest(TotalInterest);
    setTotalcost(Totalcost);



  };

  const updateRange = (e) => {
    setYears(e.target.value);

  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setemailconfirmation("");
  };

  const handleChange = (event) => {
      
    const HPtarget = event.target;
    if (!HPtarget.value) {
      setPrincipal(0);
    }
    else {
      setPrincipal(parseInt(event.target.value));
    }
  };

  const DepositChange = (event) => {

    const Dtarget = event.target;




    if (!Dtarget.value) {

      setDeposit(0);

    }


    else {
      setDeposit(parseInt(event.target.value));
    }
  };

  const interestChange = (event) => {

    const itarget = event.target;




    if (!itarget.value) {


      // setInterest(1);


    }


    else if(itarget.value === "0"){
      itarget.value=0.1;
      setInterest(0.1);
    }

    else {
      setInterest(event.target.value);
    }
  };



  function sendEmail(e) {
    e.preventDefault();
    var template_params = e.target;
    console.log(template_params);

    //   template_params = {
    //     'username': 'James',
    //     'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
    // }



    emailjs.sendForm('service_e8bg1n4', 'template_uie3rol', e.target, 'user_57ZDfl4uo4LQbBWxzpD2r')
      .then((result) => {
        console.log(result.text);
        setemailconfirmation("Thank you! you'll receive an email shortly");
        // handleClose();
      }, (error) => {
        console.log(error.text);
        setemailconfirmation("Error");
      });

  }
  useEffect(() => {
    calculate();
  }, [years, interest, deposit, principal]);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">{/* <img src={logo} /> */}</header>
        <div className="mainholder">
          <div className="branding">
            <div className="heading">
              <Typography variant="h2" align="left">
                Repayments Calculator
              </Typography>
            </div>

            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="calculatorholder">
            <div className="leftholder leftalign">

              <form className="inputform" noValidate autoComplete="off">
                {/* <div className="heading">
                <p>See what a mortgage costs, find out what the repayments might be. </p>
                </div> */}

                <InputLabel htmlFor="house-price">House Price</InputLabel>
                <TextField
                  value={principal}
                  onChange={handleChange}
                  name="houseprice"
                  id="house-price"
                  fullWidth
                  // variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />

                <InputLabel htmlFor="deposit">Deposit</InputLabel>

                <TextField
                  value={deposit}
                  onChange={DepositChange}
                  name="numberformat"

                  id="deposit"
                  // variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />

         



<InputLabel htmlFor="interest-rate">
                  Interest rate
                </InputLabel>
                <TextField
                  defaultValue="4"
                  //value={interest}
                   type="Number"
                  id="outlined-start-adornment"
                  // variant="outlined"
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="end">% p.a</InputAdornment>
                    ),
                  
                  }}
                  fullWidth
                  onChange={interestChange}
                // onChange={(e) => setInterest(e.target.value)}
                />

                <div className="leftalign">
                  <InputLabel htmlFor="loan-term">Loan term</InputLabel>
                  <Select
                    native
                    defaultValue={30}
                    fullWidth
                    // variant="outlined"
                    //onChange={(e) => setYears(e.target.value)}
                    onChange={updateRange}
                    inputProps={{
                      name: "age",
                      id: "age-native-simple",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={1}>1 Year</option>
                    <option value={2}>2 Years</option>
                    <option value={3}>3 Years</option>
                    <option value={4}>4 Years</option>
                    <option value={5}>5 Years</option>
                    <option value={6}>6 Years</option>
                    <option value={7}>7 Years</option>
                    <option value={8}>8 Years</option>
                    <option value={9}>9 Years</option>
                    <option value={10}>10 Years</option>
                    <option value={11}>11 Years</option>
                    <option value={12}>12 Years</option>
                    <option value={13}>13 Years</option>
                    <option value={14}>14 Years</option>
                    <option value={15}>15 Years</option>
                    <option value={16}>16 Years</option>
                    <option value={17}>17 Years</option>
                    <option value={18}>18 Years</option>
                    <option value={19}>19 Years</option>
                    <option value={20}>20 Years</option>
                    <option value={21}>21 Years</option>
                    <option value={22}>22 Years</option>
                    <option value={23}>23 Years</option>
                    <option value={24}>24 Years</option>
                    <option value={25}>25 Years</option>
                    <option value={26}>26 Years</option>
                    <option value={27}>27 Years</option>
                    <option value={28}>28 Years</option>
                    <option value={29}>29 Years</option>
                    <option value={30}>30 Years</option>
                  </Select>
                </div>
                <br />

                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    calculate();
                  }}
                >
                  Calculate
                </Button>
              </form>
            </div>
            <div className="rightholder" id="printable-area">
              <div className="results">
                <div className="heading">
                  <Typography variant="h3" align="center">
                    Your minimum repayments
                  </Typography>


                </div>
                <div className="Payments">

                  <div id="getStarted">
                    <span>Enter House price to get started</span>

                  </div>

                  <div id="Housepriceerror">
                    <span>House price should be greater than deposit</span>

                  </div>

                  <div className="housesvgholder">
                    <svg
                      height="180px" width="190px" fill="red"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px" y="0px" viewBox="0 -5 100 101"
                      id="housesvg">
                      <defs>

                        <linearGradient id="progress" x1="0" y1="1" x2="0" y2="0">
                          <stop id="stop1" offset="0" stopColor="#08c836" />
                          <stop id="stop2" offset="0" stopColor="#00b3ff" />
                          <stop id="stop3" offset="0" stopColor="#00b3ff" />
                          {/* <stop id="stop4" offset="0.2" stop-color="#ffd300" /> */}

                        </linearGradient>

          
                      </defs>
                      <path stroke="url(#myGradient)" fill="url(#progress)" d="M50,0a7.36,7.36,0,0,0-4.81,1.76L2.13,39A5.18,5.18,0,0,0,.29,44.88c.28.76,1.49,3.24,5.24,3.24h5.9V85.27a6.93,6.93,0,0,0,6.92,6.92h63.3a6.93,6.93,0,0,0,6.92-6.92V48.12h5.9c3.75,0,5-2.48,5.24-3.24A5.18,5.18,0,0,0,97.87,39l-8.64-7.47a3.21,3.21,0,0,1-.66-1.45V15.61a6.93,6.93,0,0,0-6.92-6.92H74.73a7,7,0,0,0-6.54,4.65L54.81,1.76A7.36,7.36,0,0,0,50,0Z"></path>


                    </svg>
                    <div className="legends" id="legends">
                      <div className="FirstRow"><p>Interest rate</p><span className="legendrect"></span></div>
                      <div className="SecondRow"><p>Loan amount</p><span className="legendrect"></span></div>

                    </div>
                  </div>
                  <div className="resultholder">
                    <div className="monthly recurrence">
                      <p>Monthly</p> <span> {Monthlyresult}</span>
                    </div>
                    <br></br>
                    <div className="Fortnightly recurrence">
                      <p> Fortnightly</p> <span> {Fortnightlyresult}</span>
                    </div>
                    <br></br>
                    <div className="weekly recurrence">
                      <p> Weekly</p> <span> {Weeklyresult}</span>
                    </div>
                  </div>

                </div>

                <div className="resultlist">
                  <Typography variant="subtitle1">Loan Amount</Typography>
                  <span> {Totalloan}</span>
                </div>

                <div className="resultlist">
                  <Typography variant="subtitle1">
                    Total interest payable
                  </Typography>
                  <span> {TotalInterest}</span>
                </div>

                <div className="resultlist">
                  <Typography variant="subtitle1"> Total cost</Typography>{" "}
                  <span> {Totalcost}</span>
                </div>
              </div>
              <div className="cta">
                {/* <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  calculate();
                }}
              >
                Get in touch
              </Button> */}


                <div className="ctagroup">
                  <IconButton
                    aria-label="PrintIcon"
                    color="primary"
                    onClick={() => {
                      window.print();
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                  <IconButton
                    aria-label="email"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    <EmailIcon />
                  </IconButton>
                </div>
              </div>
              <div className="disclaimer">
                Disclaimer: Whilst every effort has been made to ensure the
                accuracy of this calculator, the results should be used as an
                indication only. They are neither a quote nor a
                pre-qualification for a home loan.
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >

          <DialogTitle id="form-dialog-title">
            Send it on your email
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              To send this calculation on your phone please enter your email
              address below.
            </DialogContentText>
            <form className="contact-form" onSubmit={sendEmail}>

              <InputLabel htmlFor="age-native-simple">Email Address</InputLabel>
              <TextField
                autoFocus
                margin="dense"
                name="customer_email"
                id="emailtest"
                type="email"
                fullWidth
              />

              <input type="hidden" name="Totalloan_email" value={Totalloan}></input>
              <input type="hidden" name="TotalInterest_email" value={TotalInterest}></input>
              <input type="hidden" name="Totalcost_email" value={Totalcost}></input>
              <input type="hidden" name="Monthlyresult_email" value={Monthlyresult}></input>
              <input type="hidden" name="Fortnightlyresult_email" value={Fortnightlyresult}></input>
              <input type="hidden" name="Weeklyresult_email" value={Weeklyresult}></input>


              <DialogActions>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Cancel
            </Button>
                <Button type="submit" variant="contained" color="primary">
                  Send
            </Button>
   
         

              </DialogActions>
            </form>
            <p className="ConfirmationMessage">
                {emailconfirmation}
            </p>
          </DialogContent>


        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default CompoundInterest;
