import {createMuiTheme} from '@material-ui/core'
import{red, green} from '@material-ui/core/colors'


const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#ccc',
      main: '#4254b6',
      dark: '#ccc',
      contrastText: '#fff',
      },
      secondary: {
        main: '#00c731',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
      
        'Omnes-pro',
        // '-apple-system',
        // 'BlinkMacSystemFont',
        // '"Segoe UI"',
        // 'Roboto',
        // '"Helvetica Neue"',
        // 'Arial',
        // 'sans-serif',
        // '"Apple Color Emoji"',
        // '"Segoe UI Emoji"',
        // '"Segoe UI Symbol"',
      ].join(','),

      h3:{
        fontSize: '1.2rem',
        fontWeight: 400,
      
      },
      h2:{
        fontSize: '1.4rem',
        fontWeight: 900,
        color:'#4254b6',
      
      },
      subtitle1:{
        fontSize: '1rem',
        fontWeight: 600,
      
      },  
      subtitle2:{
        fontSize: '1rem',
        fontWeight: 600,
      
      },
      caption:{
        fontSize: '1.2rem',
        fontWeight: 400,
      
      },

     
    },
    overrides: {
      // Style sheet name ⚛️
      MuiButton: {
        // Name of the rule
        text: {
          // Some CSS
         // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
          borderRadius: 3,
          border: 0,
          color: 'white',
          height: 48,
          padding: '0 30px',
          boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        },
      },
    },
  });

  export default theme;